<template>
    <div class="autocompleteProduct">
        <v-autocomplete
            :min-len="3"
            v-model="editedProduct"
            :items="productNames"
            :get-label="getLabel"
            :component-item='itemTemplate'
            @update-items="updateProductsList"
            :auto-select-one-item="false"
            @change="triggerChange"
        />
        <p class="autocompleteProductText" v-if="product.showNotFoundText">{{notFound}}</p>
    </div>
</template>

<script>
    import TrxItem from './transactionItem.vue'

    export default {
        name: 'productItemInput',
        props: {
            product : {
                type: Object
            },
            notFound: {
                type: String
            },
            index: {
                type: Number
            }
        },
        data() {
            return {
                productNames: null,
                itemTemplate: TrxItem,
                editedProduct: null
            }
        },
        mounted(){
            this.editedProduct = this.product
        },
        methods: {
            triggerChange(item) {
                if(item.length <3) {
                    this.productNames = []
                }
                if (item === '') {
                    this.product.showNotFoundText = true
                }
            },
            getLabel (item) {
		        return !!item.name ? item.name : ''
	        },
            updateProductsList(val) {
                let params = {
                    search: val,
                }

                orderAxios
                    .get('product/get', { params })
                    .then(({ data }) => {
                        this.productNames = []

                        data.product_list.forEach(item => {
                            this.productNames.push({
                                name: item.name,
                                id: item.id,
                                quantity: 0
                            })
                        })
                        if(!data.product_list.length) {
                            this.product.showNotFoundText = true
                        } else {
                            this.product.showNotFoundText = false
                        }
                    })
                    .catch(error => {
                        console.error(error)
                        EventBus.$emit('showAlertModal', error.response.data.error)
                    })

            },
            expandQuantity() {
                let data = {
                        index: this.index,
                        showText: this.product.showNotFoundText
                    }
            },
        },
        watch: {
            editedProduct: function(newVal, oldVal) {
                if (newVal) {
                    this.$emit('setProduct', newVal)
                }
            },
            // product: function(newVal, oldVal) {
            //     console.log('newVal', newVal)
            //     if(!newVal.name.length) {
            //         this.productNames = []
            //         this.editedProduct = {
            //             name: '',
            //             id: null
            //         }
            //         this.product.showNotFoundText = true
            //         this.$forceUpdate()
            //     }
            // },
            productNames : function(newVal, oldVal) {
                if (newVal !== oldVal) {
                    // this.expandQuantity()
                }
            }
        }
    }
</script>

<style scoped>
.autocompleteProduct {
    display: inline-block;
    width: 85%;
}
.autocompleteProductText {
    margin-top: 3px;
    margin-bottom: 0.9rem;
    text-align: end;
    color: red;
    opacity: 0.7;
}

</style>