<template>
  <div class="main">
    <div class="container">
      <div class="trxTitle productListTitle">
        <h1>{{ activeLanguage.combinedProducts }}</h1>
        <button type="submit" class="btn productListTitleBtn" @click="goToCreateTransaction">
          {{ activeLanguage.addNewCombinedProduct }}
        </button>
      </div>

      <div class="trxMainTables">
        <div class="trxTable">
          <div class="col-12" style="margin-top: 1rem; margin-bottom: 2rem;">
            <input type="text" v-model="search" class="form-control trxSearchInput" @change="getTransactions()"
                   style="font-size: 16px !important;" :placeholder="activeLanguage.search">
          </div>
          <div>
            <div class="trxProductListTable">
              <div class="row trxTableHeader">
                <div class="col-1 trxProductListTableTh">ID</div>
                <div class="col-2 trxProductListTableTh">{{ activeLanguage.transactionName }}</div>
                <div class="col-2 trxProductListTableTh">{{ activeLanguage.price }}</div>
                <div class="col-6 trxProductListTableTh"></div>
                <div class="col-1 trxProductListTableTh"></div>
              </div>

              <div class="row trxProductListTableTbodyTr" v-for="(transaction, index) in transactionsList"
                   :key="transaction.id">
                <template v-if="!transaction.expand">
                  <div class="col-1 trxProductListTableTd">
                    <p class="trxProductListTableP">
                      {{ transaction.transaction_id }}
                    </p>
                  </div>
                  <div class="col-2 trxProductListTableTd">
                    <p class="trxProductListTableP">
                      {{ transaction.transaction_name }}
                    </p>
                  </div>
                  <div class="col-2 trxProductListTableTd">
                    <p class="trxProductListTableP">
                      {{ transaction.currency === 'EUR' ? '€' : '$' }} {{ formattedAmount(transaction.price) }}
                    </p>
                  </div>
                  <div class="col-6 trxProductListTableTd">
                    <p class="trxProductListTableP"></p>
                  </div>
                  <div class="col-1 trxProductListTableTd trxArrow">
                    <i class="fa fa-angle-down" aria-hidden="true" @click="expandItem(index)"></i>
                  </div>
                </template>

                <template v-if="transaction.expand && !transaction.edit">
                  <div class="trxProductListExpandedTd">
                    <div class="trxExpandedHeader">
                      <div class="col-1 trxProductListTableTd">
                        <p class="trxProductListTableP">
                          {{ transaction.transaction_id }}
                        </p>
                      </div>
                      <div class="col-2 trxProductListTableTd">
                        <p class="trxProductListTableP">
                          {{ transaction.transaction_name }}
                        </p>
                      </div>
                      <div class="col-2 trxProductListTableTd">
                        <p class="trxProductListTableP">
                          {{ transaction.currency === 'EUR' ? '€' : '$' }} {{ formattedAmount(transaction.price) }}
                        </p>
                        <p class="trxProductListTableP">
                          {{ transaction.currency === 'EUR' ? '€' : '$' }} {{ formattedAmount(transaction.btw_amount) }}
                          BTW
                        </p>
                      </div>
                      <div class="col-5 trxProductListTableTd">
                        <div class="trxSizes">
                          <p class="trxProductListTableP" style="width: 75px;">
                            QR image
                          </p>
                          <p class="trxProductListTableP" style="">
                            : <a :href=transaction.qr style="text-decoration: underline">QR Image Link</a>
                          </p>
                        </div>
                      </div>
                      <div class="col-2 trxProductListTableTd trxArrow" style="margin-left: 30px;">
                        <i class="fa fa-pencil" aria-hidden="true" style="margin-right: 15px"
                           @click="editItem(index)"></i>
                        <i class="fa fa-angle-up" aria-hidden="true" @click="expandItem(index)"></i>
                      </div>
                    </div>

                    <div class="trxProductListExpandedTd">
                      <div class="trxExpandedBody">
                        <div class="col-1 trxProductListTableTd">
                          <p class="trxProductListTableP"></p>
                        </div>
                        <div class="col-4 trxProductListTableTd">
                          <div class="trxProducts">
                            <div class="trxProductsHeader">
                              <div class="col-6">
                                <p class="trxProductListTableP" style="font-size: 17px !important;">Product list</p>
                              </div>
                              <div class="col-6">
                                <p class="trxProductListTableP" style="font-size: 17px !important;">Quantity</p>
                              </div>
                            </div>
                            <div class="trxProductsBody" style="flex-direction: column;">
                              <template v-for="product in transaction.products">
                                <div class="trxProductsBody">
                                  <div class="col-8">
                                    <p class="trxProductListTableP">{{ product.name }}</p>
                                  </div>
                                  <div class="col-2">
                                    <p class="trxProductListTableP">{{ product.quantity }}</p>
                                  </div>
                                </div>
                              </template>
                            </div>
                          </div>
                        </div>
                        <div class="col-3 trxProductListTableTd">
                          <div class="trxQr">
                            <img :src="transaction.qr" class="img-responsive" alt="" width="150">
                          </div>
                        </div>
                        <div class="col-3 trxProductListTableTd">
                          <p class="trxProductListTableP">{{ activeLanguage.timesScanned }}:
                            {{ transaction.scanned_times }}</p>
                          <p class="trxProductListTableP">{{ activeLanguage.placedOrders }}:
                            {{ transaction.order_created_times }}</p>
                          <p class="trxProductListTableP">Rejected: {{ transaction.credit_check_rejected_times }}</p>
                        </div>
                      </div>
                      <div class="trxExpandedBody col-12">
                        <div class="btns" style="margin-left: auto;">
                          <div class="text-right" v-if="!transaction.edit" style="margin-bottom: 15px">
                            <button type="button" class="btn btn-danger productListTitleBtn trxDeleteBtn"
                                    @click="deleteItem(transaction)">{{ activeLanguage.delete }}
                            </button>
                            <button class="btn productListTitleBtn" style="width: auto;" @click="editItem(index)">
                              {{ activeLanguage.edit }}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>

                <template v-if="transaction.expand && transaction.edit">
                  <div class="trxProductListExpandedTd">
                    <div class="trxExpandedHeader">
                      <div class="col-1 trxProductListTableTd">
                        <p class="trxProductListTableP">
                          {{ transaction.transaction_id }}
                        </p>
                      </div>

                      <div class="col-2 trxProductListTableTd">
                        <p class="trxProductListTableP">
                          <input type="text" v-model="transaction.transaction_name" class="form-control" id="name"
                                 minlength="3" required>
                        </p>
                      </div>

                      <div class="col-2 trxProductListTableTd"></div>

                      <div class="col-5 trxProductListTableTd">
                        <div class="trxSizes" style="height: 100%;">
                          <p class="trxProductListTableP" style="width: 75px; margin-top: auto; margin-bottom: auto;">
                            QR image
                          </p>
                          <p class="trxProductListTableP" style="margin-top: auto; margin-bottom: auto;">
                            : <a :href=transaction.qr style="text-decoration: underline">QR Image Link</a>
                          </p>
                        </div>
                      </div>

                      <div class="col-2 trxProductListTableTd trxArrow" style="margin-left: 30px;">
                        <i class="fa fa-pencil" aria-hidden="true" style="margin-right: 15px"
                           @click="editItem(index)"></i>
                        <i class="fa fa-angle-up" aria-hidden="true" @click="expandItem(index)"></i>
                      </div>
                    </div>

                    <div class="trxProductListExpandedTd">
                      <div class="trxExpandedBody">
                        <div class="col-1 trxProductListTableTd">
                          <p class="trxProductListTableP"></p>
                        </div>
                        <div class="col-4 trxProductListTableTd">
                          <div class="trxProducts">
                            <div class="trxProductsHeader">
                              <div class="col-9">
                                <p class="trxProductListTableP" style="font-size: 17px !important;">Product list</p>
                              </div>
                              <div class="col-3">
                                <p class="trxProductListTableP" style="font-size: 17px !important;">Quantity</p>
                              </div>
                            </div>
                            <div class="trxProductsBody" style="flex-direction: column;">
                              <template v-for="(product, prodIndex) in transaction.products">
                                <div class="trxProductsBody">
                                  <div class="col-9" style="margin-top: 16px; padding: 0;">
                                    <div class="input-group">
                                      <div class="input-group-addon"
                                           @click="removeProduct(prodIndex, transaction, product)">
                                        <i class="fas fa-times-circle" aria-hidden="true"></i>
                                      </div>
                                      <ProductItemInput
                                          :product="product"
                                          @setProduct="setProduct($event, prodIndex, index)"
                                          :notFound="activeLanguage.notFound"
                                          :index="prodIndex"
                                          :key="product.id"
                                      />
                                    </div>
                                  </div>
                                  <div class="col-3">
                                    <p class="trxProductListTableP">
                                      <input type="text" class="form-control" v-model="product.quantity"
                                             :placeholder="activeLanguage.quantity">
                                    </p>
                                  </div>
                                </div>
                              </template>
                              <div class="col-12 row addMore" @click="addProduct(index)" v-if="transaction.edit">
                                <div class="input-group-addon" v-if="showAddBtn">
                                  <i class="fas fa-plus" aria-hidden="true"></i>
                                </div>
                                <p class="addMore-text">{{ addBtnText }}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-3 trxProductListTableTd">
                          <div class="trxQr">
                            <img :src="transaction.qr" class="img-responsive" alt="" width="150">
                          </div>
                        </div>
                        <div class="col-3 trxProductListTableTd">
                          <p class="trxProductListTableP">{{ activeLanguage.timesScanned }}:
                            {{ transaction.scanned_times }}</p>
                          <p class="trxProductListTableP">{{ activeLanguage.placedOrders }}:
                            {{ transaction.order_created_times }}</p>
                          <p class="trxProductListTableP">Rejected: {{ transaction.credit_check_rejected_times }}</p>
                        </div>
                      </div>
                      <div class="trxExpandedBody col-12">
                        <div class="btns" style="margin-left: auto;">
                          <div class="text-right" v-if="transaction.edit" style="margin-bottom: 15px">
                            <button class="btn productListTitleBtn" style="width: auto; margin-right: 10px;"
                                    @click="cancelEditItem(index)">{{ activeLanguage.cancel }}
                            </button>
                            <button type="button" class="btn btn-danger productListTitleBtn" style="width: auto;"
                                    @click="saveItem(transaction)">{{ activeLanguage.save }}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import {mapGetters} from 'vuex'
import ProductItemInput from '../../components/trxView/productItemInput.vue'

export default {
  name: 'TrxTransactionList',
  data() {
    return {
      search: null,
      transactionsList: [],
      pagination: {
        currentPage: 1,
        maxItems: 0,
        productsPerPage: 20
      },
      editProductsItems: [],
      editProductsQuantity: [],
      productNames: [],
      selectedProducts: [],
      addBtnText: '',
      showAddBtn: true
    }
  },
  mounted() {
    this.getTransactions()
    this.addBtnText = this.activeLanguage.addMore
  },
  computed: {
    ...mapGetters({
      activeLanguage: 'getTrxLanguage'
    }),
    formattedAmount() {
      return (amount) => Helper.formatAmount(amount)
    }
  },
  methods: {
    goToCreateTransaction() {
      this.$router.push('/trxcreatetransaction')
    },
    getTransactions(page = 1, perPage = 20) {
      let queryString = window.location.search
      let searchParams = new URLSearchParams(queryString)
      let transactionId = searchParams.get('id')

      if (transactionId) {
        this.search = transactionId
        window.history.replaceState(null, '', window.location.pathname);
      }

      let params = {
        search: this.search,
        page,
        perPage
      }

      orderAxios
      .get('transaction/get', {params})
      .then(({data}) => {
        this.transactionsList = data.transactions_list
        this.transactionsList.map((item) => {
          item.expand = item.transaction_id === this.search
          item.edit = false
          item.disableSaveBtn = true
        })
        this.pagination.maxItems = data.total_transactions;
      })
      .catch(error => {
        console.error(error)
        EventBus.$emit('showAlertModal', error.response.data.error)
      })
    },
    expandItem(index) {
      this.transactionsList[index].expand = !this.transactionsList[index].expand
      this.$forceUpdate()
    },
    editItem(index) {
      this.transactionsList[index].edit = !this.transactionsList[index].edit
      this.$forceUpdate()
    },
    deleteItem(transaction) {
      let confirmDelete = confirm(this.activeLanguage.confirmText)

      if (confirmDelete) {
        orderAxios
        .delete(`transaction/delete/${transaction.id}`)
        .then(({data}) => {
          EventBus.$emit('showAlertModal', data.message)
          this.getTransactions()
        })
        .catch(error => {
          console.error(error)
          EventBus.$emit('showAlertModal', error.response.data.error)
        })
      }
    },
    setProduct(product, prodIndex, transIndex) {
      this.transactionsList[transIndex].products[prodIndex].id = product.id
      this.transactionsList[transIndex].products[prodIndex].name = product.name
    },
    addProduct(index) {
      let defProduct = {
        name: '',
        quantity: 0,
        id: null
      }
      if (this.transactionsList[index].products.length <= 9) {
        this.transactionsList[index].products.push(defProduct)
      } else if (this.transactionsList[index].products.length >= 10) {
        this.addBtnText = this.activeLanguage.productsLimit
        this.showAddBtn = false
      }
      this.$forceUpdate();
    },
    removeProduct(index, transaction, product) {
      if (transaction.products.length !== 1) {
        transaction.products.splice(index, 1)
      }
      if (transaction.products.length < 10) {
        this.addBtnText = this.activeLanguage.addMore
        this.showAddBtn = true
      }
      transaction.disableSaveBtn = false
      this.$forceUpdate()
    },
    saveItem(transaction) {
      transaction.products.map((item, index) => {
        transaction.products[index].id = item.id
        transaction.products[index].quantity = parseInt(transaction.products[index].quantity)
      })

      let params = {
        name: transaction.transaction_name,
        products: transaction.products
      }

      orderAxios
      .patch(`transaction/edit/${transaction.id}`, params)
      .then(({data}) => {
        EventBus.$emit('showAlertModal', data.message)
        this.getTransactions()
      })
      .catch(error => {
        console.error(error)
        EventBus.$emit('showAlertModal', error.response.data.error)
      })
    },
    cancelEditItem(index) {
      this.getTransactions()
    },
  },
  components: {
    ProductItemInput
  }
}
</script>

<style>
.logoTitle {
  display: flex;
  justify-content: center;
}

.logoField {
  /* width: 200px; */
  /* height: 200px; */
  width: 50%;
  height: 50%;
  max-height: 200px;
  /* border-radius: 10px; */
  /* background-color: #DBDADD; */
  display: flex;
  flex-direction: column;
  text-align: center;
}

.logoInner {
  margin-top: auto;
  margin-bottom: auto;
}

.logoImg {
  max-width: 100%;
  max-height: 200px;
  /* border-radius: 10px; */
  margin: auto;
  width: auto;
  height: auto;
  object-fit: cover;
}

.customFileInput {
  /* width: 200px; */
  height: 200px;
  position: absolute;
  opacity: 0;
}

.image {
  display: flex;
}

.deleteLogo {
  color: #d8271a;
  z-index: 20;
  position: absolute;
  padding: 7px;
  margin-left: 310px;
  margin-top: -15px;
}

.logoField {
  width: 150px;
  height: 150px;
  max-height: 200px;
  /* border-radius: 10px; */
  /* background-color: #DBDADD; */
  display: flex;
  flex-direction: column;
  text-align: center;
}

.logoInner {
  margin-top: auto;
  margin-bottom: auto;
}

.logoImg {
  max-width: 100%;
  max-height: 200px;
  /* border-radius: 10px; */
  margin: auto;
  width: auto;
  height: auto;
  object-fit: cover;
}

.img-responsive {
  /* margin-top: 20px; */
  margin-bottom: auto;
  /* margin-left: 20px; */
  margin-right: auto;
}
</style>