import { render, staticRenderFns } from "./productItemInput.vue?vue&type=template&id=6520dc85&scoped=true&"
import script from "./productItemInput.vue?vue&type=script&lang=js&"
export * from "./productItemInput.vue?vue&type=script&lang=js&"
import style0 from "./productItemInput.vue?vue&type=style&index=0&id=6520dc85&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6520dc85",
  null
  
)

export default component.exports